import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "@ui/wrapper"
import Heading from "@ui/heading"
import List, { ListItem } from "@ui/list"
import { SectionWrap } from "./style"
// import Dot from "@ui/divider/dot"
import Line from "@ui/divider/line";
import SectionTitle from "@ui/section-title"

const ConferencesAera = ({ sectionTitleStyle, ListThreeStyle }) => {
  const getConferencesData = useStaticQuery(graphql`
    query GET_Conferences_DATA {
      pubs: publicationJson {
        pubs {
          author
          category
          journal
          link
          note
          title
          type
          volume
          year
        }
      }
    }
  `)
  const {
    pubs: { pubs },
  } = getConferencesData

  const year_list = pubs.map(pub => {
    return pub.year
  })
  const years = [...new Set(year_list.sort().reverse())]

  function check_is_null(pubs, year, cate) {
    var i = 0
    pubs.map(pub => {
      if (pub.year === year && pub.category.includes(cate)) {
        i++
      }
    })
    return i
  }
  var m = 0
  function empty_return(m){
    if (m != 0){
      return (
            <Row>
            <Col lg={12}>
              <SectionTitle
                {...sectionTitleStyle}
                subtitle="Oops, it seems to be empty here..."
              />
            </Col>
          </Row>
          )
        }
  }
  const textStyle = {
    color:"#6d6fb0",
    display: "unset",
  }

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            {years.map(year => {
              if (check_is_null(pubs, year, "Referred Conferences") != 0) {
                return (
                  <Row>
                    <Col lg={12}>
                      <Heading as="h6" mb="15px">
                        {year}
                      </Heading>
                      <List {...ListThreeStyle}>
                        {pubs.map(pub => {
                          if (pub.year === year && pub.category.includes("Referred Conferences")) {
                            return (
                              <font size="2">
                                <ListItem>
                                {pub.author + " "}
                                  <a
                                    display="inline"
                                    href={pub.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={textStyle}
                                  >
                                    {pub.title}
                                  </a>
                                  <b> {pub.journal} </b>
                                  {pub.volume}
                                  {pub.year}.
                                  <font color="#FF8C00"> <div dangerouslySetInnerHTML={{__html:pub.note}} style={{display: "inline"}}></div> </font>
                                </ListItem>
                              </font>
                            )
                          }
                        })}
                      </List>
                      {/* <Dot mt="40px" /> */}
                      <Line mt="40px" mb="40px" borderStyle="dashed" />
                    </Col>
                  </Row>
                )
              }
              else {
                m++
              }
            })}
            {empty_return(m)}
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

ConferencesAera.defaultProps = {
  ListThreeStyle: {
    layout: "circle",
  },
  sectionTitleStyle: {
    mb: "30px",
    responsive: {
      small: {
        mb: "20px",
      },
    },
  },
}

export default ConferencesAera
